import { Caption } from '@xo-union/tk-ui-typography';
import React from 'react';
import Styles from './AvailabilityLegend.scss';

export const AvailabilityLegend = () => {
	return (
		<div className={Styles.legendContainer}>
			<div className={Styles.legendIcon} />
			<div>
				<Caption size="lg">Availability</Caption>
			</div>
			<div className={`${Styles.legendIcon} ${Styles.partial}`} />
			<div>
				<Caption size="lg">Partially available</Caption>
			</div>
		</div>
	);
};
