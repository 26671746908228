import Calendar from '@components/calendar/Calendar';
import analytics from '@constants/analytics';
import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useAppSelector } from '@redux/hooks';
import { DropdownContainer } from '@xo-union/tk-component-datepicker';
import useIsModalBreakpoint from '@xo-union/tk-component-datepicker/lib/useIsModalBreakpoint';
import { Field } from '@xo-union/tk-component-fields';
import Icon from '@xo-union/tk-component-icons';
import { Bone, Skeleton } from '@xo-union/tk-ui-skeleton';
import { format } from 'date-fns';
import { useAtomValue } from 'jotai/index';
import React, { useCallback, useRef } from 'react';
import type { Decorated } from 'types/vendor';
import VendorAnalytics from '../../../../decorators/vendorAnalytics';
import { AvailabilityLegend } from '../AvailabilitySection/AvailabilityLegend';
import {
	availabilityDataAtom,
	availabilityLoading,
} from '../AvailabilitySection/availability-atoms';
import type { BookingAnalyticsProps } from './BookNow';
import Styles from './BookNowDateField.scss';

export interface BookNowDateFieldProps {
	selectedDate: Date | undefined;
	onDateChange: (date: Date) => void;
	isDateValid: boolean;
	bookingAnalytics: BookingAnalyticsProps;
}

export const BookNowDateField = ({
	selectedDate,
	onDateChange,
	isDateValid,
	bookingAnalytics,
}: BookNowDateFieldProps) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const [isOpen, setIsOpen] = React.useState(false);
	const availabilityData = useAtomValue(availabilityDataAtom);
	const isAvailabilityLoading = useAtomValue(availabilityLoading);
	const vendor = useAppSelector((state) => state.vendor.vendor as Decorated);
	const vendorAnalytics = new VendorAnalytics(vendor);
	const { track } = useAnalyticsContext();
	const isModal = useIsModalBreakpoint();

	const openCalendar = useCallback(() => {
		setIsOpen(true);
	}, []);

	const closeCalendar = useCallback(() => {
		setIsOpen(false);
	}, []);

	const handleOnDateChange = useCallback(
		(date) => {
			onDateChange(date);
			setIsOpen(false);
			track({
				event: analytics.bookingEvents.WEDDING_DATE_SELECTED,
				properties: {
					...vendorAnalytics.baseEventProps(),
					...bookingAnalytics,
					marketplace_action: analytics.marketplaceActions.BOOKING,
					wedding_date_selected: date,
					action_type: analytics.actionTypes.SELECT,
				},
			});
		},
		[onDateChange, track, vendorAnalytics, bookingAnalytics],
	);

	const handleContainerBlur = useCallback(
		(evt: FocusEvent) => {
			const focusLeftContainer = !containerRef.current?.contains(
				evt.relatedTarget as Node,
			);
			if (!isModal && evt.relatedTarget && focusLeftContainer) {
				close();
			}
		},
		[isModal],
	);

	const handleKey = useCallback(
		(evt: React.KeyboardEvent<HTMLInputElement>) => {
			if (evt.key === 'Space' || evt.key === ' ') {
				setIsOpen(!isOpen);
				evt.preventDefault();
			}

			if (evt.key === 'Escape') {
				setIsOpen(false);
				evt.preventDefault();
			}
		},
		[isOpen],
	);

	if (isAvailabilityLoading) {
		return (
			<Skeleton>
				<Bone style={{ height: 48, width: '100%', borderRadius: 4 }} />
			</Skeleton>
		);
	}

	return (
		<div className={Styles.dateField}>
			<div className={Styles.iconWrapper}>
				<Icon
					name="date"
					size="md"
					onClick={openCalendar}
					aria-label="Event date"
				/>
			</div>
			<Field
				onClick={openCalendar}
				label="Event date*"
				name="Event date"
				value={selectedDate ? format(selectedDate, 'MM/dd/yyyy') : ''}
				validations={[
					{
						name: 'date',
						validate: () => {
							if (!selectedDate) return 'Please select an event date';
							return isDateValid ? null : 'Selected date is not available';
						},
					},
				]}
				onKeyDown={handleKey}
				readOnly
			/>
			<DropdownContainer
				isOpen={isOpen}
				ref={containerRef}
				numberOfMonths={1}
				initialFocusRef={containerRef}
				onClose={closeCalendar}
				onBlur={handleContainerBlur}
			>
				<div className={Styles.dropdownContents}>
					<Calendar
						availabilityData={availabilityData}
						onDayClick={handleOnDateChange}
					/>
					<AvailabilityLegend />
				</div>
			</DropdownContainer>
		</div>
	);
};
