import { CalendarView } from '@components/calendar/CalendarView';
import noop from '@utils/noop';
import { addYears, startOfToday } from 'date-fns';
import React, { memo } from 'react';
import { type Matcher, RootProvider } from 'react-day-picker';
import { CalendarProvider } from './CalendarContext';
import type { DayStatus } from './constants';
import type { AvailabilityCalendarAction, AvailabilityResponse } from './types';
import { CALENDAR_RANGE_YEARS } from './utils';

export interface Props {
	numberOfMonths?: 1 | 2;
	fromDate?: Date;
	toDate?: Date;
	fromMonth?: Date;
	toMonth?: Date;
	fromYear?: number;
	toYear?: number;
	onMonthChange?: (month: Date) => void;
	disabled?: Matcher | Matcher[];
	selected?: string;
	onDayClick?: (day: Date, dayStatus: DayStatus) => void;
	onNextClick?: () => void;
	onPrevClick?: () => void;
	availabilityData: AvailabilityResponse | null;
	weddingDate?: string | null;
	trackCalendar?: (action: AvailabilityCalendarAction) => void;
}

const Calendar = ({
	numberOfMonths = 1,
	fromDate = new Date(),
	toDate = addYears(new Date(), CALENDAR_RANGE_YEARS + 1),
	fromMonth,
	toMonth,
	fromYear,
	toYear,
	onMonthChange = noop,
	disabled = {
		before: startOfToday(),
	},
	selected,
	onDayClick,
	onPrevClick,
	onNextClick,
	availabilityData,
	weddingDate,
	trackCalendar,
}: Props) => {
	if (!availabilityData) {
		return null;
	}

	return (
		<RootProvider
			numberOfMonths={numberOfMonths}
			fromDate={fromDate}
			toDate={toDate}
			fromMonth={fromMonth}
			toMonth={toMonth}
			fromYear={fromYear}
			toYear={toYear}
			onMonthChange={onMonthChange}
			disabled={disabled}
			showOutsideDays={false}
			selected={selected ? new Date(selected) : undefined}
		>
			<CalendarProvider
				onDayClick={onDayClick}
				onPrevClick={onPrevClick}
				onNextClick={onNextClick}
				availabilityData={availabilityData}
				weddingDate={weddingDate}
				trackCalendar={trackCalendar}
			>
				<CalendarView />
			</CalendarProvider>
		</RootProvider>
	);
};

export default memo(Calendar);
