import { TooltipWrapper } from '@components/tooltip/TooltipWrapper';
import analytics from '@constants/analytics';
import { useAppSelector } from '@redux/hooks';
import noop from '@utils/noop';
import { compose } from '@xo-union/react-css-modules';
import { Button } from '@xo-union/tk-component-buttons';
import { Form, Option, Select } from '@xo-union/tk-component-fields';
import MembershipModalRedux, {
	type OpenModalType,
} from '@xo-union/tk-component-membership-modal-redux';
import { DisplayButton } from '@xo-union/tk-ui-links';
import {
	Body1,
	Body2,
	Caption,
	Subhead,
	Subtle,
} from '@xo-union/tk-ui-typography';
import { format } from 'date-fns';
import { useAtomValue } from 'jotai';
import React, { useCallback, useState, useEffect } from 'react';
import { packagesAtom } from '../../../../jotai/storefront/detailed-pricing';
import urlSafe from '../../../../utils/urlHelpers';
import { BookNowInfo } from '../../pages/StorefrontBooking/components/BookNowInfo/BookNowInfo';
import { availabilityLoading } from '../AvailabilitySection/availability-atoms';
import Styles from './BookNow.scss';
import { BookNowDateField } from './BookNowDateField';
import {
	type BookingAnalyticsProps,
	useBookNowAnalytics,
} from './useBookNowAnalytics';
import { useBookNowFormData } from './useBookNowFormData';

export const HowBookingWorksTooltip = ({
	arrowOffset,
	bookingAnalytics,
}: { arrowOffset: number; bookingAnalytics: BookingAnalyticsProps }) => {
	const { track, vendorAnalytics } = useBookNowAnalytics();
	const cta = 'How booking works';

	const onMouseOverHandler = useCallback(() => {
		track({
			event: analytics.bookingEvents.HOW_BOOKING_WORKS_CLICKED,
			properties: {
				...vendorAnalytics.baseEventProps(),
				...bookingAnalytics,
				action_type: analytics.actionTypes.CLICK,
				cta,
			},
		});
	}, [track, vendorAnalytics, bookingAnalytics]);

	return (
		<TooltipWrapper
			target={
				<DisplayButton
					type="button"
					onClick={noop}
					onMouseOver={onMouseOverHandler}
					classes={compose({
						'tappable-area': Styles.bookInfoButton,
					})}
				>
					{cta}
				</DisplayButton>
			}
			arrowPosition="top"
			arrowOffset={arrowOffset}
			className={Styles.tooltip}
		>
			<BookNowInfo />
		</TooltipWrapper>
	);
};

const Header = ({
	bookingAnalytics,
}: { bookingAnalytics: BookingAnalyticsProps }) => {
	const vendorName = useAppSelector((state) => state.vendor.vendor?.name);

	return (
		<div className={Styles.headerWrapper}>
			<div className={Styles.headingContainer}>
				<Subhead as="span" bold>
					Ready to book?
				</Subhead>
				<Caption as="span" className={Styles.headingRequired}>
					* = Required
				</Caption>
			</div>
			<div>
				{`When you know, you know. Request to book with ${vendorName} today. `}
				<div>
					<HowBookingWorksTooltip
						arrowOffset={10}
						bookingAnalytics={bookingAnalytics}
					/>
				</div>
			</div>
		</div>
	);
};

export const PackageSelectionField = ({
	setSelectedPackage,
	defaultValue,
	bookingAnalytics,
}: {
	setSelectedPackage: (value: string) => void;
	defaultValue: string | undefined;
	bookingAnalytics: BookingAnalyticsProps;
}) => {
	const packages = useAtomValue(packagesAtom);
	const { track, vendorAnalytics } = useBookNowAnalytics();

	useEffect(() => {
		if (defaultValue) {
			setSelectedPackage(defaultValue);
		}
	}, [defaultValue, setSelectedPackage]);

	const onClickHandler = useCallback(
		(packageTitle) => {
			setSelectedPackage(packageTitle);
			track({
				event: analytics.bookingEvents.PRICING_PACKAGE_SELECTED,
				properties: {
					...vendorAnalytics.baseEventProps(),
					...bookingAnalytics,
					pricing_package_selected: packageTitle,
					action_type: analytics.actionTypes.SELECT,
				},
			});
		},
		[track, vendorAnalytics, setSelectedPackage, bookingAnalytics],
	);

	return (
		<Select
			label="Select*"
			name="Package"
			onChange={(e) => onClickHandler(e.target.value)}
			validations={[{ required: true }]}
			defaultValue={defaultValue}
			subTextOnInvalid="Please select a package"
		>
			{packages.map((pkg) => (
				<Option key={pkg.title} value={pkg.title}>
					{pkg.title}
				</Option>
			))}
		</Select>
	);
};

const SubmitCtaField = ({
	buttonText = 'Start booking',
}: { buttonText?: string }) => {
	const isFetchingAvailability = useAtomValue(availabilityLoading);

	return (
		<>
			<div className={Styles.bookTotalWrapper}>
				<div className={Styles.bookTotalInfo}>
					<Body1 bold>Save the date deposit</Body1>
					<Body2>Applied to the total</Body2>
				</div>
				<div>
					<Body1 bold>$100</Body1>
				</div>
			</div>

			<div className={Styles.cta}>
				<Button
					color="primary"
					type="submit"
					disabled={isFetchingAvailability}
					block
				>
					{buttonText}
				</Button>
				<div className={Styles.ctaDisclaimer}>
					<Subtle size={'sm'} as="div">
						You won't be charged yet.
					</Subtle>
					<Subtle size={'sm'} as="div">
						Vendor's terms apply.
					</Subtle>
				</div>
			</div>
		</>
	);
};

const doRedirect = (selectedDate: Date, selectedPackage: string) => {
	const params = new URLSearchParams({
		'selected-date': urlSafe(format(selectedDate, 'MM-dd-yyyy')),
		'selected-package': urlSafe(selectedPackage),
	});
	const pathAddition = `/booking?${params.toString()}`;
	window.location.href = window.location.pathname + pathAddition;
};

export interface BookNowProps {
	preselectedPackage?: string;
	headerText?: string;
	bookingAnalytics: BookingAnalyticsProps;
}

export const BookNow = ({
	preselectedPackage = '',
	bookingAnalytics,
}: BookNowProps) => {
	const [openMembershipModalType, setOpenMembershipModalType] =
		useState<OpenModalType>();
	const isLoggedIn = useAppSelector((state) =>
		Boolean(state.membership.member),
	);
	const { track, vendorAnalytics } = useBookNowAnalytics();
	const buttonText = 'Start booking';

	const {
		isDateValid,
		isFormValid,
		selectedDate,
		selectedPackage,
		setSelectedDate,
		setSelectedPackage,
	} = useBookNowFormData();

	const submitBookNow = useCallback(() => {
		if (!isFormValid) return;
		if (isLoggedIn) doRedirect(selectedDate as Date, selectedPackage);
		if (!isLoggedIn) setOpenMembershipModalType('LOG_IN');
		track({
			event: analytics.bookingEvents.BOOKING_BUTTON_CLICKED,
			properties: {
				...vendorAnalytics.baseEventProps(),
				...bookingAnalytics,
				marketplace_action: analytics.marketplaceActions.BOOKING,

				cta: buttonText,
				action_type: analytics.actionTypes.CLICK,
			},
		});
	}, [
		isLoggedIn,
		isFormValid,
		selectedDate,
		selectedPackage,
		track,
		vendorAnalytics,
		bookingAnalytics,
	]);

	const closeModal = (actionWasSuccessful: boolean) => {
		setOpenMembershipModalType(undefined);
		if (actionWasSuccessful) {
			doRedirect(selectedDate as Date, selectedPackage);
		}
	};

	return (
		<>
			<div className={Styles.container}>
				<Form className={Styles.top} onSubmit={submitBookNow}>
					<Header bookingAnalytics={bookingAnalytics} />
					<BookNowDateField
						selectedDate={selectedDate}
						onDateChange={setSelectedDate}
						isDateValid={isDateValid}
						bookingAnalytics={bookingAnalytics}
					/>
					<div>
						<PackageSelectionField
							setSelectedPackage={setSelectedPackage}
							defaultValue={preselectedPackage}
							bookingAnalytics={bookingAnalytics}
						/>
					</div>
					<SubmitCtaField buttonText={buttonText} />
					<div className={Styles.divider} />
				</Form>
				<div className={Styles.disclaimerText}>
					<Subtle bold>Remaining payments</Subtle>
					<Body1>
						This deposit secures your booking request with the vendor through
						The Knot. All remaining payments, including the final balance, will
						be handled directly with the vendor and are governed by the vendor's
						terms.
					</Body1>
				</div>
			</div>
			<MembershipModalRedux
				metadata={{
					userAction: 'book now',
					applicationName: 'marketplace',
				}}
				openModalType={openMembershipModalType}
				onClickLogIn={() => setOpenMembershipModalType('LOG_IN')}
				onClickSignUp={() => setOpenMembershipModalType('SIGN_UP')}
				onSignUpSuccess={() => closeModal(true)}
				onLogInSuccess={() => closeModal(true)}
				onClose={() => closeModal(false)}
			/>
		</>
	);
};
