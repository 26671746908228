import analytics from '@constants/analytics';
import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useAppSelector } from '@redux/hooks';
import type { Decorated } from 'types/vendor';
import VendorAnalytics from '../../../../decorators/vendorAnalytics';

export interface BookingAnalyticsProps {
	marketplace_action: string;
	page_section: string;
	source_page: string;
	booking_step: string;
}

export const storefrontAnalytics = {
	marketplace_action: analytics.marketplaceActions.BOOKING,
	page_section: analytics.bookingPageSections.MAIN,
	source_page: analytics.STOREFRONT,
	booking_step: '0',
};

export const bookingDetailsAnalytics = {
	marketplace_action: analytics.marketplaceActions.BOOKING,
	page_section: analytics.bookingPageSections.MAIN,
	source_page: analytics.BOOKING_DETAILS,
	booking_step: '1',
};

export const useBookNowAnalytics = () => {
	const vendor = useAppSelector((state) => state.vendor.vendor as Decorated);
	const vendorAnalytics = new VendorAnalytics(vendor);
	const { track } = useAnalyticsContext();

	return {
		track,
		vendorAnalytics,
	};
};
