import { useAppSelector } from '@redux/hooks';
import { format, isPast, isValid, parse } from 'date-fns';
import { useAtomValue } from 'jotai/index';
import { useMemo, useState } from 'react';
import { availabilityDataAtom } from '../AvailabilitySection/availability-atoms';

const getDateOrUndefined = (dateString: string) => {
	try {
		const date = parse(dateString, 'yyyy-MM-dd', new Date());
		return isValid(date) && !isPast(date) ? date : undefined;
	} catch {
		return undefined;
	}
};

export const useBookNowFormData = () => {
	const availabilityData = useAtomValue(availabilityDataAtom);
	const [selectedPackage, setSelectedPackage] = useState('');
	const weddingDate = useAppSelector(
		(state) => state.membership.member?.wedding_date,
	);
	const [selectedDate, setSelectedDate] = useState(
		weddingDate ? getDateOrUndefined(weddingDate) : undefined,
	);

	const isDateValid = useMemo(() => {
		if (!selectedDate) return false;
		const dateStringForValidation = format(selectedDate, 'yyyy-MM-dd');
		const isDateUnavailable =
			availabilityData?.availability[dateStringForValidation]?.isAvailable ===
			false;
		return !isDateUnavailable;
	}, [availabilityData, selectedDate]);

	const isFormValid = useMemo(() => {
		return Boolean(isDateValid && selectedPackage);
	}, [isDateValid, selectedPackage]);

	return {
		selectedDate,
		selectedPackage,
		isDateValid,
		setSelectedDate,
		setSelectedPackage,
		isFormValid,
	};
};
