import Icon from '@xo-union/tk-component-icons';
import { Body1 } from '@xo-union/tk-ui-typography';
import React from 'react';
import Styles from './BookNowInfo.scss';

export const TOOLTIP_LIST = [
	{
		title: 'Request to book',
		content: 'Select your date and package, then submit a $100 deposit.',
	},
	{
		title: 'Wait for approval',
		content: 'The vendor has 7 days to accept or decline your request.',
	},
	{
		title: 'Finalize booking',
		content:
			'Coordinate remaining details and payments with the vendor directly, according to their standard deposit and payment terms.',
	},
] as const;

export const BookNowInfo = () => {
	return (
		<div className={Styles.tooltipContainer}>
			<div className={Styles.tooltipBody} data-testid="tooltip">
				<ol>
					{TOOLTIP_LIST.map((item) => (
						<li key={item.title}>
							<Body1 as="span" bold>
								{item.title}
							</Body1>
							<Body1>{item.content}</Body1>
						</li>
					))}
				</ol>
			</div>
			<div className={Styles.tooltipFooter}>
				<Icon name="info" size="md" data-testid="svg-icon" />
				<div>
					If the vendor doesn’t respond or declines your request for any reason,{' '}
					<Body1 as="span" bold>
						your $100 deposit will be fully refunded.
					</Body1>
				</div>
			</div>
		</div>
	);
};
