import Styles from '@components/calendar/Calendar.scss';
import { useCalenderContext } from '@components/calendar/CalendarContext';
import { DayProps } from '@components/calendar/types';
import classNames from 'classnames';
import { format, isBefore } from 'date-fns';
import React, { useCallback, useMemo, useRef } from 'react';
import { useDayRender } from 'react-day-picker';
import { DayStatus } from './constants';
import { DATE_KEY_FORMAT } from './utils';

const getIsUnavailableDay = (date: Date) => {
	const { unavailableDays } = useCalenderContext();

	return useMemo(() => {
		const dateString = format(date, DATE_KEY_FORMAT);
		return unavailableDays.includes(dateString) || isBefore(date, new Date());
	}, [date, unavailableDays]);
};

const getIsPartialDay = (date: Date) => {
	const { partiallyAvailableDays } = useCalenderContext();

	return useMemo(() => {
		const dateString = format(date, DATE_KEY_FORMAT);
		return partiallyAvailableDays.includes(dateString);
	}, [date, partiallyAvailableDays]);
};

const getDayStatus = (
	isPartialDay: boolean,
	isUnavailableDay: boolean,
): DayStatus => {
	return useMemo(() => {
		if (isUnavailableDay) return DayStatus.Unavailable;
		if (isPartialDay) return DayStatus.PartiallyAvailable;
		return DayStatus.Available;
	}, [isPartialDay, isUnavailableDay]);
};

export const Day = ({ date, displayMonth }: DayProps) => {
	const { onDayClick, weddingDate, trackCalendar } = useCalenderContext();
	const buttonRef = useRef<HTMLButtonElement>(null);
	const {
		isHidden,
		activeModifiers: { selected },
	} = useDayRender(date, displayMonth, buttonRef);
	const dateTime = format(date, 'P');
	const display = format(date, 'd');
	const dayStatus = getDayStatus(
		getIsPartialDay(date),
		getIsUnavailableDay(date),
	);
	const isWeddingDay = weddingDate == format(date, DATE_KEY_FORMAT);

	const onClick = useCallback(() => {
		if (dayStatus === DayStatus.Unavailable) return;
		trackCalendar?.('Click on a day');
		onDayClick(date, dayStatus);
	}, [trackCalendar, onDayClick, date, dayStatus]);

	return (
		<div className={Styles.monthDayWrapper}>
			<button
				className={classNames(Styles.button, Styles.monthDay, {
					[Styles.available]: dayStatus === DayStatus.Available,
					[Styles.weddingDay]: isWeddingDay,
					[Styles.disabled]: dayStatus === DayStatus.Unavailable,
					[Styles.partialDay]: dayStatus === DayStatus.PartiallyAvailable,
					[Styles.selected]: selected,
					[Styles.hiddenDay]: isHidden,
				})}
				type="button"
				onClick={onClick}
				onKeyDown={onClick}
			>
				<time dateTime={dateTime}>{display}</time>
			</button>
		</div>
	);
};
