import Styles from '@components/calendar/Calendar.scss';
import { Month } from '@components/calendar/month';
import { useSwipeHandler } from '@hooks/useSwipeHandler';
import classNames from 'classnames';
import React from 'react';
import { useDayPicker } from 'react-day-picker';
import { useCalendarNavigation } from './hooks/useCalendarNavigation';

export const CalendarView = () => {
	const dayPicker = useDayPicker();
	const { handlePreviousClick, handleNextClick, navigation } =
		useCalendarNavigation();
	const calendarRef = useSwipeHandler(handleNextClick, handlePreviousClick);

	return (
		<>
			<div
				id={dayPicker.id}
				className={classNames(Styles.calendar, {
					[Styles.twoMonths]: navigation.displayMonths.length === 2,
				})}
				ref={calendarRef}
			>
				<Month idx={0} displayMonth={navigation.displayMonths[0]} />
				{navigation.displayMonths.length === 2 && (
					<Month idx={1} displayMonth={navigation.displayMonths[1]} />
				)}
			</div>
		</>
	);
};
