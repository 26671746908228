import { useCalenderContext } from '@components/calendar/CalendarContext';
import { useCallback } from 'react';
import { useNavigation } from 'react-day-picker';

export const useCalendarNavigation = () => {
	const navigation = useNavigation();
	const { previousMonth, nextMonth, goToMonth } = navigation;
	const { onPrevClick, onNextClick, trackCalendar } = useCalenderContext();

	const handlePreviousClick = useCallback(() => {
		trackCalendar?.('Arrow left');
		if (!previousMonth) return;
		goToMonth(previousMonth);
		onPrevClick();
	}, [previousMonth, goToMonth, onPrevClick, trackCalendar]);

	const handleNextClick = useCallback(() => {
		trackCalendar?.('Arrow right');
		if (!nextMonth) return;
		goToMonth(nextMonth);
		onNextClick();
	}, [nextMonth, goToMonth, onNextClick, trackCalendar]);

	return { handlePreviousClick, handleNextClick, navigation };
};
