import { useCallback, useEffect, useRef } from 'react';

export const useSwipeHandler = (
	onSwipeLeft: () => void,
	onSwipeRight: () => void,
	SWIPE_THRESHOLD = 50,
) => {
	const calendarRef = useRef<HTMLDivElement>(null);
	const startX = useRef(0);

	const onTouchStart = useCallback((e: TouchEvent) => {
		startX.current = e.touches[0].clientX;
	}, []);

	const onTouchMove = useCallback((e: TouchEvent) => {
		const deltaX = e.touches[0].clientX - startX.current;
		const deltaY = e.touches[0].clientY - e.touches[0].clientY;

		if (Math.abs(deltaY) > Math.abs(deltaX)) return;
	}, []);

	const onTouchEnd = useCallback(
		(e: TouchEvent) => {
			const deltaX = e.changedTouches[0].clientX - startX.current;

			if (deltaX < -SWIPE_THRESHOLD) {
				onSwipeLeft();
			} else if (deltaX > SWIPE_THRESHOLD) {
				onSwipeRight();
			}
		},
		[onSwipeLeft, onSwipeRight, SWIPE_THRESHOLD],
	);

	useEffect(() => {
		const calendarElement = calendarRef.current;
		if (!calendarElement) return;

		calendarElement.addEventListener('touchstart', onTouchStart);
		calendarElement.addEventListener('touchmove', onTouchMove);
		calendarElement.addEventListener('touchend', onTouchEnd);

		return () => {
			calendarElement.removeEventListener('touchstart', onTouchStart);
			calendarElement.removeEventListener('touchmove', onTouchMove);
			calendarElement.removeEventListener('touchend', onTouchEnd);
		};
	}, [onTouchStart, onTouchMove, onTouchEnd]);

	return calendarRef;
};
