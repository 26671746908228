import analytics from '@constants/analytics';
import { Button } from '@xo-union/tk-component-buttons';
import Modal from '@xo-union/tk-component-modals';
import { Subtle } from '@xo-union/tk-ui-typography';
import cx from 'classnames';
import React, { useCallback, useState } from 'react';
import { BookNow } from '../../../client/pages/Storefront/components/BookNow/BookNow';
import {
	storefrontAnalytics,
	useBookNowAnalytics,
} from '../../pages/Storefront/components/BookNow/useBookNowAnalytics';
import Styles from './packageBookCta.scss';

export interface PackageBookCtaProps {
	collapse?: boolean;
	packageTitle: string;
}

const bookingAnalytics = {
	...storefrontAnalytics,
	page_section: analytics.bookingPageSections.PRICING,
};

export const PackageBookCta = ({
	collapse,
	packageTitle,
}: PackageBookCtaProps) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { track, vendorAnalytics } = useBookNowAnalytics();
	const packageCtaText = 'Start booking';

	const onClickHandler = useCallback(() => {
		setIsModalOpen(true);
		track({
			event: analytics.bookingEvents.BOOKING_BUTTON_CLICKED,
			properties: {
				...vendorAnalytics.baseEventProps(),
				...bookingAnalytics,
				cta: packageCtaText,
				pricing_package_selected: packageTitle,
				action_type: analytics.actionTypes.CLICK,
			},
		});
	}, [track, packageTitle, vendorAnalytics]);

	const closeModalHandler = () => {
		setIsModalOpen(false);
	};

	return (
		<div className={Styles.bookNowContainer}>
			<div
				className={cx(Styles.buttonInteriorRow, {
					[Styles.buttonInteriorRowCollapse]: collapse,
				})}
			>
				<Button size="md" onClick={onClickHandler} block>
					{packageCtaText}
				</Button>
			</div>
			<div className={Styles.subtleText}>
				<Subtle size={'sm'} as="div">
					You won't be charged yet.
				</Subtle>
				<Subtle size={'sm'} as="div">
					Vendor's terms apply.
				</Subtle>
			</div>
			{isModalOpen && (
				<Modal onClose={closeModalHandler} className={Styles.bookNowModal}>
					<div className={Styles.bookNow}>
						<BookNow
							preselectedPackage={packageTitle}
							bookingAnalytics={bookingAnalytics}
						/>
					</div>
				</Modal>
			)}
		</div>
	);
};
