import Styles from '@components/calendar/Calendar.scss';
import type { MonthProps } from '@components/calendar/types';
import { compose } from '@xo-union/react-css-modules';
import {
	Option,
	Select,
	type SelectChangeEvent,
} from '@xo-union/tk-component-fields';
import Icon from '@xo-union/tk-component-icons';
import { Body1 } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import {
	addYears,
	eachMonthOfInterval,
	eachYearOfInterval,
	format,
	isBefore,
	startOfMonth,
} from 'date-fns';
import React, { useMemo } from 'react';
import { useDayPicker, useNavigation } from 'react-day-picker';
import { useCalenderContext } from './CalendarContext';

const IconRight = () => <Icon size="md" name="caret_right" />;
const IconLeft = () => <Icon size="md" name="caret_left" />;

export const MonthSelector = ({ displayMonth, idx }: MonthProps) => {
	const dayPicker = useDayPicker();
	const { onPrevClick, onNextClick, trackCalendar } = useCalenderContext();
	const { previousMonth, nextMonth, goToMonth } = useNavigation();

	const commonDropdownClassNames = {
		input: Styles.dropdownInput,
		caret: Styles.dropdownArrow,
		list: Styles.dropdownList,
	};
	const monthDropdownClassNames = {
		...commonDropdownClassNames,
		container: Styles.monthDropdownContainer,
	};
	const yearDropdownClassNames = {
		...commonDropdownClassNames,
		container: Styles.yearDropdownContainer,
	};

	const monthDropdownClasses = compose(monthDropdownClassNames);
	const yearDropdownClasses = compose(yearDropdownClassNames);

	const handlePreviousClick: React.MouseEventHandler = () => {
		trackCalendar?.('Arrow left');
		if (!previousMonth) return;
		goToMonth(previousMonth);
		onPrevClick();
	};

	const handleNextClick: React.MouseEventHandler = () => {
		trackCalendar?.('Arrow right');
		if (!nextMonth) return;
		goToMonth(nextMonth);
		onNextClick();
	};

	const maxDate = dayPicker.toDate || addYears(new Date(), 3);
	const maxYear = maxDate.getFullYear();
	const maxMonth = maxDate.getMonth();
	const fromDate = dayPicker.fromDate || new Date();
	const selectedYear = displayMonth.getFullYear();

	const availableMonths = useMemo(() => {
		return Array.from(
			new Set(
				eachMonthOfInterval({ start: fromDate, end: maxDate }).reduce(
					(acc, month) => {
						const monthIndex = month.getMonth();

						if (month.getFullYear() !== selectedYear) return acc;

						if (selectedYear === maxYear && monthIndex > maxMonth) return acc;

						acc.push({ month: format(month, 'LLLL'), index: monthIndex });
						return acc;
					},
					[] as { month: string; index: number }[],
				),
			),
		);
	}, [fromDate, maxDate, selectedYear, maxYear, maxMonth]);

	const handleMonthChange = (e: SelectChangeEvent) => {
		trackCalendar?.('Change month');
		const month = e.target.value;
		const year = displayMonth.getFullYear();
		const monthIndex = availableMonths.find((m) => m.month === month)?.index;

		if (monthIndex === undefined) return;

		const newMonth = new Date(year, monthIndex, 1);
		goToMonth(
			isBefore(newMonth, startOfMonth(fromDate))
				? addYears(newMonth, 1)
				: newMonth,
		);
	};

	const availableYears = eachYearOfInterval({
		start: fromDate,
		end: maxDate,
	}).map((year) => format(year, 'yyyy'));

	const handleYearChange = (e: SelectChangeEvent) => {
		trackCalendar?.('Change year');
		const year = Number.parseInt(e.target.value);
		const currentMonth = displayMonth.getMonth();

		if (Number.isNaN(year)) return;

		if (year >= maxYear) {
			goToMonth(new Date(maxYear, maxMonth, 1));
			return;
		}

		const newYear = new Date(year, currentMonth, 1);
		goToMonth(isBefore(newYear, fromDate) ? fromDate : newYear);
	};

	return (
		<div className={Styles.monthSelector}>
			<Select
				name=""
				value={format(displayMonth, 'LLLL')}
				classes={monthDropdownClasses}
				onChange={handleMonthChange}
			>
				{availableMonths.map(({ month }) => (
					<Option key={month} value={month}>
						<Body1 bold aria-live="polite" role="presentation">
							{month}
						</Body1>
					</Option>
				))}
			</Select>
			<Select
				name=""
				value={format(displayMonth, 'yyyy')}
				classes={yearDropdownClasses}
				onChange={handleYearChange}
			>
				{availableYears.map((year) => (
					<Option key={year} value={year}>
						<Body1 bold aria-live="polite" role="presentation">
							{year}
						</Body1>
					</Option>
				))}
			</Select>
			<span className={Styles.monthSelectorArrow}>
				<button
					type="button"
					className={classNames(Styles.button, {
						[Styles.hiddenMd]: idx === 1,
						[Styles.hide]: !previousMonth,
					})}
					onClick={handlePreviousClick}
				>
					<span className={Styles.srOnly}>Previous month</span>
					<IconLeft />
				</button>
				<button
					type="button"
					className={classNames(Styles.button, {
						[Styles.hide]: !nextMonth,
					})}
					onClick={handleNextClick}
				>
					<span className={Styles.srOnly}>Next month</span>
					<IconRight />
				</button>
			</span>
		</div>
	);
};
